import React from 'react'
import '../sass/preloader.scss'
import { motion } from 'framer-motion'

const Preloader = ({ title }) => {
  return (
    <motion.div
      className="preloader-container"
      initial={{ opacity: 1, y: 0 }}
      animate={{ opacity: 0, y: -100, visibility: 'hidden' }}
      transition={{ delay: 2 }}
    >
      <motion.p initial={{ y: 100 }} animate={{ y: 0 }}>
        {title}
      </motion.p>
    </motion.div>
  )
}

export default Preloader
