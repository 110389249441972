import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import HeroSection from './pages/HeroSection'
import Service from './pages/Service'
import CallToAction from './components/CallToAction'
import Footer from './components/Footer'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Work from './pages/Work'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
// import Pricing from './components/Pricing'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Preloader from './components/Preloader'

function App() {
  const [isLoading, setLoading] = useState(true)
  const handleNavigation = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  useEffect(() => {
    AOS.init({ duration: 3000 })
  }, [])
  return (
    <div className="App">
      <Router>
        <Header handleNavigation={handleNavigation} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                {isLoading && <Preloader title="&copy; infinite create" />}
                <HeroSection />
                <Service />
                {/* <Pricing /> */}
                <CallToAction />
                <Footer />
              </>
            }
          />
          <Route
            path="/work"
            exact
            element={
              <>
                {isLoading && <Preloader title="Work" />}
                <Work />
                <Footer />
              </>
            }
          />

          <Route
            path="/about"
            exact
            element={
              <>
                {isLoading && <Preloader title="About" />}
                <AboutUs />
              </>
            }
          />
          <Route
            path="/contact"
            exact
            element={
              <>
                {isLoading && <Preloader title="Contact" />}
                <ContactUs />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  )
}

export default App
