import React from 'react'
import '../sass/service.scss'
import img1 from '../assets/branding.webp'
import img2 from '../assets/uxui.webp'
import img3 from '../assets/webiste.webp'
import img4 from '../assets/graphicdesign.webp'
import globeIcon from '../assets/globe.svg'
import { motion } from 'framer-motion'

const Service = () => {
  const imgURL = [
    {
      title: 'Web design',
      image: img3,
    },

    {
      title: 'UI/UX',
      image: img2,
    },

    {
      title: 'Brand design',
      image: img1,
    },
    {
      title: 'Graphic design',
      image: img4,
    },
  ]
  return (
    <div className="service-section">
      <div className="container">
        <motion.div
          className="service-header d-flex space-between"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        >
          <div className="service-text">
            <h1>Craft, code and smile.</h1>
            <p>We build experiences that connect brands.</p>
          </div>
          <div className="service-tab d-flex ">
            <span className="tabs d-flex">
              <img src={globeIcon} alt="" />
              Development
            </span>

            <span className="tabs d-flex">
              <img src={globeIcon} alt="" />
              Design
            </span>
            <span className="tabs d-flex">
              <img src={globeIcon} alt="" />
              UI/UX
            </span>
            <span className="tabs d-flex">
              <img src={globeIcon} alt="" />
              Brand design
            </span>
          </div>
        </motion.div>

        <div className="services d-flex ">
          {imgURL.map((img, index) => {
            return (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="service-item"
                key={index}
              >
                <figure>
                  <img src={img.image} alt={img.title} loading="lazy" />
                  <figcaption className="d-flex">{img.title}</figcaption>
                </figure>
              </motion.div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Service
