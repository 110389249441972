import React from 'react'
import { Link } from 'react-router-dom'
import '../sass/callToAction.scss'

const CallToAction = () => {
  return (
    <div className="cta-section container">
      <div className=" text-center">
        <h1>Let's discuss your ideas</h1>

        <div className="btn-group d-flex">
          <Link to="/contact" className="btn-contact">
            Let's talk
          </Link>

          <a
            rel="noreferrer"
            href="https://www.instagram.com/infinitecreate_"
            target="_blank"
            className="btn-follow"
          >
            Follow us
          </a>
        </div>
      </div>
    </div>
  )
}

export default CallToAction
