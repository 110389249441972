import MDF from '../assets/work/MadeenaDryFruits.webp'
import ApnaAdda from '../assets/work/ApnaAddaMockup.webp'

import JackMiller from '../assets/work/JackMiller-Mockup.webp'
import TohfaTraders from '../assets/work/TohfaTraders.webp'

const Project = [
  {
    title: 'Madeena Dry Fruits',
    service: 'Design & Development',
    src: MDF,
    url: 'https://madeenadryfruits.com/',
    // desc:
    //   'The Madeena Dry Fruits & Khajoor Centre project involves the creation of a visually appealing logo and a user-friendly landing page for a dry fruit and dates store. The logo represents the essence of the store and its offerings, providing an instant recognition for the brand. The landing page provides an online platform for the store to showcase their products and services, making it easier for customers to purchase and learn more about their offerings.',
  },
  {
    title: 'Apna Adda',
    service: 'Branding',
    src: ApnaAdda,
    // desc:
    //   'The Apna Adda project entails the design of a brand identity for a tea shop. The logo is a symbol that represents the tea shop and its unique personality, and is intended to be easily recognizable by customers. The shop banner, on the other hand, is a physical representation of the brand that can be displayed at the storefront, making the shop easily recognizable to passersby.',
  },

  {
    title: 'Jack Miller',
    service: 'Logo design',
    src: JackMiller,
  },
  {
    title: 'Tohfa Traders',
    service: 'Logo design',
    src: TohfaTraders,
  },
]

export default Project
