import React from 'react'
import { Fade } from 'react-awesome-reveal'
import logo from '../assets/logo.png'
import '../sass/footer.scss'

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-logo d-flex">
        <Fade>
          <img src={logo} alt="infinite create." />
          <span>infinite create.</span>
        </Fade>
      </div>
      <div className="footer-links ">
        <div className="email">
          <Fade>
            <a href="mailto:infinitecreatexhu@gmail.com">
              infinitecreatexhu@gmail.com
            </a>
          </Fade>
        </div>
        <div className="footer-social-links">
          <Fade>
            <p>SOCIAL</p>
            <div className="links d-flex">
              <a
                rel="noreferrer"
                href="https://www.instagram.com/infinitecreate_"
                className="d-flex pointer"
                target="_blank"
              >
                Instagram
              </a>
              <a
                rel="noreferrer"
                href="https://www.youtube.com/@infinitecreate"
                className="d-flex pointer"
                target="_blank"
              >
                Youtube
              </a>
              <a
                rel="noreferrer"
                href="https://in.pinterest.com/infinitecreate/"
                className="d-flex pointer"
                target="_blank"
              >
                Pinterest
              </a>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Footer
