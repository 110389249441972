import '../sass/work.scss'
import React from 'react'
import Project from '../data/Project'

const Work = () => {
  return (
    <div className="container-fluid work-section">
      <div className="container ">
        {Project.map((prj, index) => {
          return (
            <div className="project" key={index}>
              <figure>
                <img
                  data-aos="fade-right"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                  src={prj.src}
                  alt={prj.title}
                />
                <figcaption
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  <p className="title">{prj.title}</p>
                  <p className="service">{prj.service}</p>
                  {prj.url && (
                    <a href={prj.url} target="_blank" rel="noreferrer">
                      Website
                    </a>
                  )}
                </figcaption>
              </figure>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Work
