import React, { useEffect } from "react";
import "../sass/about.scss";

import member1 from "../assets/umair-sheikh.webp";
import member2 from "../assets/harshad.webp";

const AboutUs = () => {
  useEffect(() => {
    document.title = "Infinite Create • About";
  }, []);
  return (
    <div className="container about-container">
      <div
        className="about-text"
        data-aos-easing="ease-in-out"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <p>
          <span>infinite create</span> is a digital and creative agency. We help
          companies to create or launch a refreshed brand, website or graphic
          design.
        </p>
      </div>
      <div
        className="team-section"
        data-aos-easing="ease-in-out"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
      >
        <h2
          data-aos-easing="ease-in-out"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          Our team
        </h2>

        <div className="team-member d-flex">
          <div className="member">
            <img
              src={member1}
              alt="Umair Sheikh"
              data-aos-easing="ease-in-out"
              data-aos="fade-up"
              data-aos-delay="100"
            />
            <div className="member-info">
              <p className="name">Umair Sheikh,Co-founder</p>

              <p className="title">Designer & Developer</p>

              <a
                href="https://umair-sheikh.web.app/"
                target="_blank"
                rel="noreferrer"
              >
                Portfolio
              </a>
            </div>
          </div>
          <div className="member">
            <img
              src={member2}
              alt="Harshad Kajale"
              data-aos-easing="ease-in-out"
              data-aos="fade-up"
              data-aos-delay="100"
            />
            <div className="member-info">
              <p className="name">Harshad Kajale,Co-founder</p>

              <p className="title">Developer & Marketer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
