import React, { useEffect } from 'react'

import '../sass/contact.scss'

const ContactUs = () => {
  useEffect(() => {
    document.title = 'Infinite Create • Contact'
  }, [])
  return (
    <div className="container contact-us-section">
      <div
        className="contact-us-text"
        data-aos-easing="ease-in-out"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        {' '}
        <p>Starting a new project or want to collaborate with us?</p>
        <p>
          Get in touch on{' '}
          <a href="mailto:infinitecreatexhu@gmail.com">
            infinitecreatexhu@gmail.com
          </a>
        </p>
      </div>
      <div
        className="contact-follow "
        data-aos-easing="ease-in-out"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <p>Follow us</p>

        <div className="follow-links d-flex">
          <a
            rel="noreferrer"
            href="https://www.instagram.com/infinitecreate_"
            className="d-flex pointer"
            target="_blank"
          >
            Instagram
          </a>
          <a
            rel="noreferrer"
            href="https://www.youtube.com/@infinitecreate"
            className="d-flex pointer"
            target="_blank"
          >
            Youtube
          </a>
          <a
            rel="noreferrer"
            href="https://in.pinterest.com/infinitecreate/"
            className="d-flex pointer"
            target="_blank"
          >
            Pinterest
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
