import '../sass/header.scss'
import logo from '../assets/logo.png'
import { NavLink, Link } from 'react-router-dom'
import React, { useRef, useState } from 'react'
import { Squeeze as Hamburger } from 'hamburger-react'

const Header = ({ handleNavigation }) => {
  const navRef = useRef()
  const [navbar, setNavbar] = useState(false)
  const toggleNavbar = () => {
    navRef.current.classList.toggle('toggle-navbar')
  }

  return (
    <header>
      <div className="container d-flex space-between">
        <div className="logo">
          <Link to="/" onClick={() => handleNavigation()}>
            <img className="branding" src={logo} alt="Infinite Create" />
          </Link>
        </div>
        <nav className="nav-menus d-flex" ref={navRef}>
          <div className="sidebar-logo">
            <Link to="/" className="pointer">
              infinite create.
            </Link>
          </div>
          <div className="nav-links d-flex">
            <ul className="d-flex">
              <li>
                <NavLink
                  onClick={() => {
                    toggleNavbar()
                    setNavbar(false)
                    handleNavigation()
                  }}
                  style={({ isActive }) => ({
                    color: isActive ? '#909090' : '#FFF',
                  })}
                  to="/work"
                >
                  Work
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    toggleNavbar()
                    setNavbar(false)
                    handleNavigation()
                  }}
                  style={({ isActive }) => ({
                    color: isActive ? '#909090' : '#FFF',
                  })}
                  to="/about"
                >
                  About us
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    toggleNavbar()
                    setNavbar(false)
                    handleNavigation()
                  }}
                  style={({ isActive }) => ({
                    color: isActive ? '#909090' : '#FFF',
                  })}
                  to="/contact"
                >
                  Let's talk
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="social-links d-flex">
            <p>SOCIAL</p>
            <div className="links d-flex">
              <a
                rel="noreferrer"
                href="https://www.instagram.com/infinitecreate_"
                className="d-flex pointer"
                target="_blank"
              >
                Instagram
              </a>
              <a
                rel="noreferrer"
                href="https://www.youtube.com/@infinitecreate"
                className="d-flex pointer"
                target="_blank"
              >
                Youtube
              </a>
              <a
                rel="noreferrer"
                href="https://in.pinterest.com/infinitecreate/"
                className="d-flex pointer"
                target="_blank"
              >
                Pinterest
              </a>
            </div>
          </div>
        </nav>
        <button className="btn btn-menu" onClick={toggleNavbar}>
          <Hamburger
            easing="ease-in"
            toggled={navbar}
            toggle={setNavbar}
            size={20}
            color="#ffffff"
          />
        </button>
      </div>
    </header>
  )
}

export default Header
