import React, { useEffect } from 'react'
import '../sass/hero-section.scss'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const HeroSection = () => {
  useEffect(() => {
    document.title = 'Infinite Create • A digital agency'
  }, [])
  return (
    <div className="hero-section container-fluid text-center">
      <div className="container">
        <div className="hero-section-text">
          <motion.div
            className="agency-name"
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <span>infinite create.</span>
            <p>
              We are <span className="digital">Digital</span> &{' '}
              <span className="creative">Creative</span> Agency
            </p>
            <Link to="/contact" className="btn-cta pointer">
              Let's talk
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
